.bg-cover {
  background-size: cover;
}
.bg-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 400px;
  color: transparent;
  opacity: 1;
  z-index: 0;
}
.bg-section:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
  content: '';
}
/*Slideshow*/

.bg-slideshow {
  margin: 0;
  padding: 0;
}
.bg-slideshow li {
  list-style: none;
}
.parallax__inner {
  position: relative;
  z-index: 1;
}
.parallax-bg {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400%;
  overflow: hidden;
  z-index: 0;
}
.parallax-bg:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.parallax-light:after {
  background-color: rgba(0, 0, 0, 0.75);
}
.parallax-dark:after {
  background-color:  rgba(0, 0, 0, 0.9);
}
.parallax-primary:after {
  opacity: 0.9;
}
.no-bg-color-parallax {
  color: #fff;
  background-color: transparent;
  overflow: hidden;
}
.bg-slideshow:after {
  content: '';
}
.bg-slideshow li .bg-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: transparent;
  background-position: 50% 50%;
  background-size: cover;
  opacity: 0;
  z-index: 0;
}
.parallax-bg .bg-slideshow li .bg-slide {
  -webkit-animation: imageAnimation 18s linear infinite 0s;
     -moz-animation: imageAnimation 18s linear infinite 0s;
      -ms-animation: imageAnimation 18s linear infinite 0s;
       -o-animation: imageAnimation 18s linear infinite 0s;
          animation: imageAnimation 18s linear infinite 0s;
  -webkit-backface-visibility: hidden;
}
.parallax-bg .bg-slideshow li:nth-child(2) .bg-slide {
  -webkit-animation-delay: 9s;
     -moz-animation-delay: 9s;
      -ms-animation-delay: 9s;
       -o-animation-delay: 9s;
          animation-delay: 9s;
}
@-webkit-keyframes imageAnimation {
  1% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
  }
  4% {
    opacity: 0;
    -webkit-transform: scale(1);
    -webkit-animation-timing-function: ease-out;
  }
  22% {
    opacity: 1;
    -webkit-transform: scale(1.1) rotate(-2deg);
  }
  44% {
    opacity: 1;
    -webkit-transform: scale(1.1) rotate(1deg);
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes imageAnimation {
  1% {
    opacity: 0;
    -moz-animation-timing-function: ease-in;
  }
  4% {
    opacity: 0;
    -moz-transform: scale(1);
    -moz-animation-timing-function: ease-out;
  }
  22% {
    opacity: 1;
    -moz-transform: scale(1.1) rotate(-2deg);
  }
  44% {
    opacity: 1;
    -moz-transform: scale(1.1) rotate(1deg);
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes imageAnimation {
  1% {
    opacity: 0;
    -o-animation-timing-function: ease-in;
  }
  4% {
    opacity: 0;
    -o-transform: scale(1);
    -o-animation-timing-function: ease-out;
  }
  22% {
    opacity: 1;
    -o-transform: scale(1.1) rotate(-2deg);
  }
  44% {
    opacity: 1;
    -o-transform: scale(1.1) rotate(1deg);
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes imageAnimation {
  1% {
    opacity: 0;
    -ms-animation-timing-function: ease-in;
  }
  4% {
    opacity: 0;
    -ms-transform: scale(1);
    -ms-animation-timing-function: ease-out;
  }
  22% {
    opacity: 1;
    -ms-transform: scale(1.1) rotate(-2deg);
  }
  44% {
    opacity: 1;
    -ms-transform: scale(1.1) rotate(1deg);
  }
  100% {
    opacity: 0;
  }
}
@keyframes imageAnimation {
  1% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  4% {
    opacity: 0;
    transform: scale(1);
    animation-timing-function: ease-out;
  }
  22% {
    opacity: 1;
    transform: scale(1.1) rotate(-2deg);
  }
  44% {
    opacity: 1;
    transform: scale(1.1) rotate(1deg);
  }
  100% {
    opacity: 0;
  }
}

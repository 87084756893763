
.circle {
    font-weight: bold;
    padding: 4px 18px;
    border-radius: 50%;
    background-color: #d01818;
    color: #fff;
    max-height: 45px;
    z-index: 2;
    font-size: 24px;
  }
  
  .how-it-works.row {
    display: flex;
  }
  .how-it-works.row .col-2 {
    display: inline-flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
  }
  .how-it-works.row .col-2::after {
    content: "";
    position: absolute;
    border-left: 3px solid #000;
    z-index: 1;
  }
  .how-it-works.row .col-2.bottom::after {
    height: 50%;
    left: 50%;
    top: 50%;
  }
  .how-it-works.row .col-2.full::after {
    height: 100%;
    left: calc(50% - 3px);
  }
  .hw-head.row .full::before {
    content: "";
    position: absolute;
    border-left: 3px solid #000;
    z-index: 1;
  }
  .mt-110{
    margin-top: 110px;
  }
  .mtb-200{
    margin-top: 160px; margin-bottom: 20px;
  }
  .mtb-200 img{
    margin:0px 30px 10px;
    height: 70px;
  }
  .hw-head.row .full::before {
    height: 50%;
    left: 50%;
    top: 0;
  }
  .how-it-works.row .col-2.full-1::after {
    height: 100%;
    left: calc(50% - 0px);
  }
  .how-it-works.row .col-2.top::after {
    height: 50%;
    left: 50%;
    top: 0;
  }
  .timeline div {
    padding: 0;
    height: 40px;
  }
  .timeline hr {
    border-top: 3px solid #000;
    margin: 0;
    top: 17px;
    position: relative;
  }
  .timeline .col-2 {
    display: flex;
    overflow: hidden;
  }
  .timeline .corner {
    border: 3px solid #000;
    width: 100%;
    position: relative;
    border-radius: 0px;
  }
  .timeline .corner2 {
    border: 0;
    width: 100%;
    position: relative;
    border-radius: 0px;
  }
  .timeline .top-right {
    left: 50%;
    top: -50%;
  }
  .timeline .left-bottom {
    left: -50%;
    top: calc(50% - 3px);
  }
  .timeline .top-left {
    left: -50%;
    top: -50%;
  }
  .timeline .right-bottom {
    left: 50%;
    top: calc(50% - 3px);
  }
  .btn-getaquote{
    background-color: #d01818;
    color: #fff;
    width: 100%;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18px;
    border: 0;
    border-radius: 0
  }
  .padd-tb-25{
    padding: 25px 0;
  }
  .btn-getaquote:hover{
    background-color: #a91111;
  }
  h2.mainHead{
    font-weight: 700;
    font-size: 40px;
    color: #d01818;
    margin-top: 144px;
  }
  h3.subHead{
    font-weight: 700;
    font-size: 25px;
    color: #d01818;
    
  }
  p.mainPra{
    font-weight:400;
    font-size: 18px;
    color: #888888;
    margin: 0 0 5px
  }
  .hw-head img{
        margin: 0 25px 20px;
  }
  .how-it-works img{
    width: 100%;
    margin: 35px 0;
  
  }
  .how-it-works.row p{
    font-weight:400;
    font-size: 16px;
    color: #888888;
    line-height: 22px;
  }
/*------------------------------------------------------------------
[Master Stylesheet]

Project:  Parthenium
Version:  1.0
Assigned to:  WebNik
Primary use:  Parthenium
-------------------------------------------------------------------*/


/* FONTS*/
@import url('https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700|Poppins:100,200,300,400,500,600,700');
@import '../fonts/font-awesome-4.7/css/font-awesome.min.css';
@import '../fonts/stroke/style.css';
@import '../fonts/elegant/style.css';
@import '../fonts/flaticon/font/flaticon.css';


/* PLUGIN CSS */
@import '../libs/bootstrap/bootstrap.css';                /* bootstrap */
@import '../plugins/bootstrap-select/css/bootstrap-select.css';   /* select customization */
@import '../plugins/headers/header.css';                /* header */
@import '../plugins/infogarphic/infogarphic.css';                /* header */
@import '../plugins/headers/yamm.css';                /* header */
@import '../plugins/slider-pro/slider-pro.css';         /* main slider */
@import '../plugins/owl-carousel/owl.carousel.css';                 /* other sliders */

@import '../plugins/animate/animate.css';                        /* animations */
@import '../plugins/datepicker/jquery.datetimepicker.css';        /* datepicker */
@import '../plugins/parallax/parallax.css';                        /* parallax */


/* MAIN CSS */
@import 'theme.css';
@import 'color.css';
@import 'responsive.css';


.container-fluid {
    padding-left: 45px;
    padding-right: 45px;
}
.b-form-contacts select, .b-form-contacts textarea, .b-form-contacts input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
    color: #777 !important;
    border: 1px solid transparent;
    padding: 13px 20px;
    letter-spacing: 0px;
    background-color: #fff;
    line-height: inherit;
    width: 100%;
    margin-bottom: 14px;
    height: auto;
    text-shadow: none;
    box-shadow: none;
    box-sizing: border-box;
    transition: border ease .238s;
    border-radius: 0 !important;
    font-size: 1rem !important;
}
.b-form-contacts .bootstrap-select > select.mobile-device:focus + .dropdown-toggle, .b-form-contacts .bootstrap-select .dropdown-toggle:focus {
    outline: 0 !important;
    outline: 0 !important;
    outline-offset: 0 !important;
}
.b-form-contacts .form-inline .bootstrap-select, .b-form-contacts .form-horizontal .bootstrap-select, .b-form-contacts .form-group .bootstrap-select {
    margin-bottom: 0;
    margin-top: 2px;
}
.text-red {
    color: #d01818 !important;
}
.red-bg {
    background: #d01818 !important;
}
.m-right-5{margin: 5px;}

.sp-next-arrow:after, .sp-next-arrow:before, .sp-previous-arrow:after, .sp-previous-arrow:before {
    background-color: transparent !important;
}
.form-body {
  position: relative;
  background-color: #0093FF;
  height: 100%;
  z-index: 1;
}
.form-body {

  background: url("../media/general/login-bg.jpg") top no-repeat ;
  background-size: cover;
}
#pagebg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.form-body > .row {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  height: 100vh;
}
.form-holder {
  position: relative;
  margin-left: 550px;
  width: 100%;
  height: 100%;
}
.form-holder {
  margin-left: 0;
}
.form-holder .form-content {
  position: relative;
  text-align: center;
}
.form-content {
  background-color: transparent;
  margin-top: 5%;
}
.form-content .logo {
  padding: 45px 0;
}
.form-content .logo img {
  width: 280px;
}

.form-content .form-items {
  display: inline-block;
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: rgb(0,0,0);
background: linear-gradient(37deg, rgba(0,0,0,0) 0%, rgba(208,24,24,0.35057773109243695) 48%, rgba(0,0,0,0) 100%);
}

.form-content .form-items {
  text-align: center;
}

.form-content h3 {
  color: #fff;
  text-align: left;
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 10px;
}
.form-content h3 {
  color: #fff;
  text-align: center;
}

.form-content p {
  color: #c58b8b;
  text-align: left;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  margin-bottom: 10px;
  text-align: center;
}

.form-content form {
  margin-bottom: 36px;
  max-width: 25%;
  margin: 0 auto;
}
.form-row>.col, .form-row>[class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-content input, .form-content .dropdown-toggle.btn-default {
  width: 100%;
  text-align: left;
  border: 0;
  outline: 0;
  border-radius: 6px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  padding: 10px 12px;
  font-size: 16px;
  font-weight: 300;
  color: #8D8D8D;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin-bottom: 14px;
  line-height: normal;

}
.form-content input, .form-content .dropdown-toggle.btn-default {
  border: 0;
  background-color: #f7f7f7;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  color: #000000;
}
.form-content .spacer {
  height: 50px;
}
.other-links {
  margin-top: 30px;
  padding: 20px;
  color: #888888;
  font-size: 13px;
}
.other-links span {
  color: #cccccc;
  text-transform: uppercase;
}
.other-links .socailArea{
  display: block;
}
.other-links .socailArea i{
  font-size: 24px;
  margin: 10px;
  color: #fff;
}
.other-links a {
  color: #888888;
  text-decoration: underline;
}
.other-links a:hover {
  color: #fff;
}
.other-links.no-bg-icon a {
  margin-right: 0;
}
.form-content .btn {
  width: 100%;
  font-size: 24px !important;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 991px){
.form-body {
  height: initial;
  min-height: 100%;
}
.form-content form {
  max-width: 85%;

}

}
@media (max-height: 991px){
.form-body {
  height: initial;
  min-height: 100%;
}


}